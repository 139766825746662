import BgeueLogo from "../images/bgue_logo.png";
import BgeLogo from "../images/bge_logo.png";

export const selectedMaintenanceTab = {
  "corrective-maintenance": "Corrective Maintenance Tickets",
  "preventive-maintenance": "Preventative Maintenance Tickets",
  "remedial-maintenance": "Remedial Maintenance Tickets",
  "string-maintenance": "String Lists",
};

export const CATEGORY = {
  CORRECTIVE: "corrective",
  REMEDIAL: "remedial",
  PREVENTATIVE: "preventative",
  PREVENTATIVE_MASTER: "preventative_master",
  PREVENTATIVE_SCHEDULE: "preventative_schedule",
};


export const COMPANY_NAME = {
  BGE: "BGE",
  BGUE: "BGUE",
};
export let CompanyLogo;
if (process.env.REACT_APP_COMPANY_NAME === COMPANY_NAME.BGE) {
  CompanyLogo = BgeLogo;
} else {
  CompanyLogo = BgeueLogo;
}