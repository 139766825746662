import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  Sidebar,
  UserAvatar,
} from "../../../components/Component";
import FilteredByList from "../../../components/FilterBadge";
import { findUpper } from "../../../utils/Utils";
import ModalViewer from "../../plant_management/ModalViewer";
import { EquipmentAndSpareContext } from "../EquipmentSparesProvider";
import { STATUSES } from "../constants";
import EquipmentAndSpareViewContainer from "../containers/EquipmentAndSpareViewContainer";
import EquipmentAndSpareFilter from "./EquipmentAndSpareFilter";
import ExportComponent from "./ExportForm";
import Head from "../../../layout/head/Head";

function EquipmentAndSpareCompact(props) {
  // REFS

  const focusSearchRef = React.useRef();

  const equipmentAndSpareContext = useContext(EquipmentAndSpareContext);

  const {
    pagination,
    showFilter,

    sfState,
    isFilterApplied,
    handleApplyFilter,
    filterOptions,
    filterParams,
    equipmentList,
    isEquipmentListLoading,
  } = equipmentAndSpareContext;
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [sm, updateSm] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [equipmentId, setEquipmentId] = useState("");

  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });

  //   // Changing state value when searching name
  useEffect(() => { }, [onSearchText]);

  //   // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };
  const plant = {};
  const supplier = {};
  const category = {};

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleNameSort = (sortOrder, field, table) => {
    handleApplyFilter({ ...filterParams, sortingOrder: sortOrder, sortingField: field, table: table });
  };
  const viewInventory = (equipmentId) => {
    setEquipmentId(equipmentId);
    toggleModal();
  };

  // const plantManagerSelectedFilter = filterParams.plantManager.map((item, key) => {
  //   return plantOptions.plantManager?.[plantOptions.plantManager?.findIndex((data) => data.value === item)];
  // });

  // const plantSelectedFilter = filterParams.plant.map((item, key) => {
  //   return plantOptions.plant?.[plantOptions.plant?.findIndex((data) => data.value === item)];
  // });
  const createExport = () => {
    setIsExportModalOpen(!isExportModalOpen);
  };
  const toggleExportModal = () => {
    setIsExportModalOpen(!isExportModalOpen);
  };

  const onSuccessfulModal = () => {
    toggleExportModal();
  };

  const getExportComponent = () => {
    return (
      <ExportComponent
        plantIds={props?.plantId}
        onSuccessfulModal={onSuccessfulModal}
        filterParams={filterParams}
        setIsExportModalOpen={setIsExportModalOpen}
      />
    );
  };

  const getComponent = () => {
    return <EquipmentAndSpareViewContainer onSuccessfulModal={toggleModal} equipmentId={equipmentId} />;
  };

  const KEY_COMPONENT_OPTION = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
  const OWNED_BY_OPTIONS = [
    { label: "BGE Owned", value: "BGE" },
    { label: "Plant Owned", value: "PLANT" },
  ];
  const manufacturerSelectedFilter = filterParams.supplier.map((item, key) => {
    return filterOptions.manufacturer?.[filterOptions.manufacturer?.findIndex((data) => data.value === item)];
  });

  const assetCategoryFilter = filterParams.category.map((item, key) => {
    return filterOptions.assetCategory?.[filterOptions.assetCategory?.findIndex((data) => data.value === item)];
  });
  const plantFilter =
    typeof filterParams.plant === "object" &&
    filterParams.plant.map((item, key) => {
      return filterOptions.plant?.[filterOptions.plant?.findIndex((data) => data.value === item)];
    });
  const selectedStatusFilter = STATUSES.find((data) => data.value === filterParams?.status);
  const keyoptionFilter = KEY_COMPONENT_OPTION.find((data) => data.value === filterParams?.isKeyComponent);

  const selectedOwnerShipFilter = OWNED_BY_OPTIONS.find((data) => data.value === filterParams?.ownership);

  for (let i = 0; i < filterOptions.plant?.length; i++) {
    plant[filterOptions.plant[i].value] = filterOptions.plant[i].label;
  }
  for (let i = 0; i < filterOptions.manufacturer?.length; i++) {
    supplier[filterOptions.manufacturer[i].value] = filterOptions.manufacturer[i].label;
  }
  for (let i = 0; i < filterOptions.assetCategory?.length; i++) {
    category[filterOptions.assetCategory[i].value] = filterOptions.assetCategory[i].label;
  }

  const sparepartsData = equipmentList?.items || [];

  const isPvPlantPage = window.location.pathname.includes("pv-plant");
  return (
    <React.Fragment>
      {!isPvPlantPage && <Head title="Brighter App | Equipment and Spares" />}
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h3" page className={"mt-5"}>
              Equipment And Spares
            </BlockTitle>
            <BlockDes className="text-soft">
              <p>You have a total of {equipmentList?.pagination?.totalCount} Spare Parts.</p>
            </BlockDes>
            <div className="d-flex flex-wrap">
              {filterParams.status ? (
                <FilteredByList data={selectedStatusFilter.label} title={"Status"} isNotMap={true} isStr={true} />
              ) : null}
              {filterParams.isKeyComponent ? (
                <FilteredByList data={keyoptionFilter.label} title={"Key Component"} isNotMap={true} isStr={true} />
              ) : null}
              {filterParams.ownership ? (
                <FilteredByList data={selectedOwnerShipFilter.label} title={"Owned By"} isNotMap={true} isStr={true} />
              ) : null}
              <FilteredByList data={manufacturerSelectedFilter} title={"Supplier"} isMap={true} isStr={false} />
              {filterParams.ownership ? (
                <FilteredByList data={plantFilter} title={"Plant"} isMap={true} isStr={false} />
              ) : null}
              <FilteredByList data={assetCategoryFilter} title={"Category"} isMap={true} isStr={false} />
              <FilteredByList data={filterParams.searchText} title="Searched by" isNotMap={true} isStr={true} />
            </div>
          </BlockHeadContent>
          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="menu-alt-r"></Icon>
              </Button>
              <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <ul className="nk-block-tools g-3">
                  <li>
                    <a
                      className={`btn btn-white btn-outline-light ${sparepartsData?.length > 0 ? "" : "disabled"}`}
                      type="export"
                      // isLoading={exportLoading}
                      onClick={() => createExport()}
                    >
                      <Icon name="download-cloud"> </Icon>
                      <span>Export</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
          {/* <BlockHeadContent></BlockHeadContent> */}
        </BlockBetween>
      </BlockHead>
      <Block>
        <DataTable className="card-stretch">
          <div className="card-inner position-relative card-tools-toggle ">
            <div className="card-title-group ">
              <div className="card-tools"></div>
              <div className="card-tools mr-n1 ">
                <ul className="btn-toolbar gx-1">
                  <li>
                    <a
                      id="search-spareparts-btn"
                      href="#search"
                      onClick={(ev) => {
                        ev.preventDefault();
                        handleApplyFilter({ ...filterParams, searchText: "" });
                        toggle();
                      }}
                      className="btn btn-icon search-toggle toggle-search"
                    >
                      <Icon name="search"></Icon>
                    </a>
                  </li>
                  <li className="btn-toolbar-sep"></li>
                  <div>
                    <div id="spareparts-filter" tag="a" className="btn btn-icon " onClick={() => sfState.toggle()}>
                      {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                      <Icon name="filter-alt"></Icon>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
            <div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      id="cancle-spareparts-search"
                      className="search-back btn-icon toggle-search  active"
                      onClick={() => {
                        setSearchText("");
                        handleApplyFilter({ ...filterParams, searchText: "" });
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      id="search-input-spareparts"
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by plant name and equipment name. Enter to search "
                      ref={(el) => {
                        focusSearchRef.current = !onSearch ? el : null;
                      }}
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          onSearchAction();
                        }
                        if (e.keyCode === 27) {
                          setSearchText("");
                          handleApplyFilter({ ...filterParams, searchText: "" });
                          toggle();
                        }
                        if (e.keyCode === 88) {
                          setSearchText("");
                        }
                      }}
                    />
                    <Button id="search-text" className="search-submit btn-icon">
                      <Icon name="search" onClick={onSearchAction}></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DataTableBody compact>
            <DataTableHead>
              <DataTableRow style={{ display: "flex" }}>
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    id="spareparts-sort"
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingField === "model" && filterParams.sortingOrder === "ASC"
                        ? handleNameSort("DESC", "model", "assets")
                        : handleNameSort("ASC", "model", "assets");
                    }}
                  >
                    Equipment Name
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      id="spareparts-asc"
                      style={{ margin: "0px 0px -3px 0px" }}
                      onClick={() => {
                        handleNameSort("ASC", "model", "assets");
                      }}
                      name={`${filterParams.sortingField === "model" && filterParams.sortingOrder === "ASC"
                          ? "caret-up-fill "
                          : "caret-up"
                        } `}
                    ></Icon>

                    <Icon
                      id="spareparts-desc"
                      onClick={() => {
                        handleNameSort("DESC", "model", "assets");
                      }}
                      name={`${filterParams.sortingField === "model" && filterParams.sortingOrder === "DESC"
                          ? "caret-down-fill"
                          : "caret-down"
                        }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingField === "name" && filterParams.sortingOrder === "ASC"
                        ? handleNameSort("DESC", "name", "plants")
                        : handleNameSort("ASC", "name", "plants");
                    }}
                  >
                    Plant Name
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px" }}
                      onClick={() => {
                        handleNameSort("ASC", "name", "plants");
                      }}
                      name={`${filterParams.sortingField === "name" && filterParams.sortingOrder === "ASC"
                          ? "caret-up-fill "
                          : "caret-up"
                        } `}
                    ></Icon>

                    <Icon
                      onClick={() => {
                        handleNameSort("DESC", "name", "plants");
                      }}
                      name={`${filterParams.sortingField === "name" && filterParams.sortingOrder === "DESC"
                          ? "caret-down-fill"
                          : "caret-down"
                        }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="sm">
                <div className="d-flex " style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingField === "manufacturer_id" && filterParams.sortingOrder === "ASC"
                        ? handleNameSort("DESC", "manufacturer_id", "assets")
                        : handleNameSort("ASC", "manufacturer_id", "assets");
                    }}
                  >
                    Supplier
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px" }}
                      onClick={() => {
                        handleNameSort("ASC", "manufacturer_id", "assets");
                      }}
                      name={`${filterParams.sortingField === "manufacturer_id" && filterParams.sortingOrder === "ASC"
                          ? "caret-up-fill "
                          : "caret-up"
                        } `}
                    ></Icon>

                    <Icon
                      onClick={() => {
                        handleNameSort("DESC", "manufacturer_id", "assets");
                      }}
                      name={`${filterParams.sortingField === "manufacturer_id" && filterParams.sortingOrder === "DESC"
                          ? "caret-down-fill"
                          : "caret-down"
                        }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "asset_category_id"
                        ? handleNameSort("DESC", "asset_category_id", "assets")
                        : handleNameSort("ASC", "asset_category_id", "assets");
                    }}
                  >
                    Asset Category
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px" }}
                      onClick={() => {
                        handleNameSort("ASC", "asset_category_id", "assets");
                      }}
                      name={`${filterParams.sortingOrder === "ASC" && filterParams.sortingField === "asset_category_id"
                          ? "caret-up-fill "
                          : "caret-up"
                        } `}
                    ></Icon>

                    <Icon
                      onClick={() => {
                        handleNameSort("DESC", "asset_category_id", "assets");
                      }}
                      name={`${filterParams.sortingOrder === "DESC" && filterParams.sortingField === "asset_category_id"
                          ? "caret-down-fill"
                          : "caret-down"
                        }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="lg">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "quantity"
                        ? handleNameSort("DESC", "quantity")
                        : handleNameSort("ASC", "quantity");
                    }}
                  >
                    Total Spares
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px" }}
                      onClick={() => {
                        handleNameSort("ASC", "quantity");
                      }}
                      name={`${filterParams.sortingOrder === "ASC" && filterParams.sortingField === "quantity"
                          ? "caret-up-fill "
                          : "caret-up"
                        } `}
                    ></Icon>

                    <Icon
                      onClick={() => {
                        handleNameSort("DESC", "quantity");
                      }}
                      name={`${filterParams.sortingOrder === "DESC" && filterParams.sortingField === "quantity"
                          ? "caret-down-fill"
                          : "caret-down"
                        }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="lg">
                <div className="sub-text" style={{ cursor: "pointer" }}>
                  <span>Contractual Quantity</span>
                </div>
              </DataTableRow>
            </DataTableHead>
            {/*Head*/}
            <div>
              <Sidebar toggleState={showFilter}>
                <div>
                  <EquipmentAndSpareFilter />
                </div>
              </Sidebar>
            </div>
            {isEquipmentListLoading ? (
              <></>
            ) : (
              sparepartsData.map((item) => {
                return (
                  <DataTableItem>
                    <DataTableRow>
                      {/* {item && item.status === "ACTIVE" ? */}
                      {/* <Link id="pvplant-view" to={`${CLIENT_PVPLANT_VIEW}`.replace(":plantId", item.id)}> */}
                      <div
                        className="user-card"
                        role="button"
                        tabIndex="0"
                        onClick={() => {
                          viewInventory(item.id);
                        }}
                      >
                        <UserAvatar theme={"primary"} className="xs" text={findUpper(item.assets.model)} />

                        <div className="user-name">
                          <span
                            data-toggle="tooltip"
                            title={item.assets.model}
                            style={{
                              width: "90px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              cursor: "pointer",
                            }}
                            className="tb-lead"
                          >
                            {item.assets.model}
                          </span>
                        </div>
                      </div>
                      {/* </Link> */}
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span
                        data-toggle="tooltip"
                        title={"item.identifier"}
                        style={{
                          width: "90px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          display: "inline-block",
                        }}
                      >
                        {item.plantId ? plant[item.plantId] : "-"}{" "}
                      </span>
                    </DataTableRow>
                    <DataTableRow size="sm">
                      <span>{supplier[item.assets.manufacturerId]}</span>
                    </DataTableRow>
                    {/* <DataTableRow size="md">
                      <span>{item.clients?.name || "NA"}</span>
                    </DataTableRow> */}
                    <DataTableRow size="md">
                      <span>{category[item.assets.assetCategoryId]}</span>
                    </DataTableRow>
                    <DataTableRow size="lg">
                      <span>{item.quantity}</span>
                    </DataTableRow>
                    <DataTableRow size="lg">
                      <span style={{ color: item.quantity < item.contractualQuantity ? "#FF9999" : "" }}>
                        {item.contractualQuantity}
                      </span>
                    </DataTableRow>
                  </DataTableItem>
                );
              })
            )}
          </DataTableBody>
          {isEquipmentListLoading && <Skeleton count={10} className="w-100" />}

          <div className="card-inner">
            {equipmentList.pagination && equipmentList.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={pagination.size}
                totalItems={equipmentList.pagination.totalCount}
                paginate={equipmentAndSpareContext.updateShowListPage}
                currentPage={equipmentList.pagination.currentPage}
                paginatePage={equipmentAndSpareContext.updateShowSizePage}
              />
            ) : (
              !isEquipmentListLoading && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </DataTable>
      </Block>
      <ModalViewer
        title="Spare Parts - Select the Export Fields"
        isOpen={isExportModalOpen}
        size="md"
        toggleModal={toggleExportModal}
        component={getExportComponent()}
      />
      <ModalViewer
        size={"xl"}
        title="Equipment And Spares"
        isOpen={isOpen}
        toggleModal={toggleModal}
        component={getComponent()}
      />
    </React.Fragment>
  );
}

export default EquipmentAndSpareCompact;
