import apiService from "../../services/ApiService";
import {
  CLIENT_TICKET_LIST,
  EXPORT_CLIENT_TASK_API,
  CLIENT_PLANT_TASK_VIEW,
  CLIENT_TASK_FILTER_OPTIONS,
  PREVENTIVE_MAINTENANCE_API,
  CORRECTIVE_MAINTENANCE_API,
  REMEDIAL_MAINTENANCE_API,
} from "../../constants/endpoints";

export const getAllTickets = async (filterParams, params) => {
  const response = await apiService.get(CLIENT_TICKET_LIST, {
    queryParams: filterParams,
    pathParams: { params },
  });
  return response;
};

export const exportClientTask = async (filterParams) => {
  const response = await apiService.get(EXPORT_CLIENT_TASK_API, {
    queryParams: filterParams,
    responseType: "blob",
    headers: {
      "Content-Type": "application/pdf",
    },
  });
  return response;
};

export const getPvPlantTask = async (taskId) => {
  const response = await apiService.get(CLIENT_PLANT_TASK_VIEW, {
    pathParams: { taskId },
  });
  return response;
};

export const getTaskListFilterOptions = async (filterParams) => {
  const response = await apiService.get(CLIENT_TASK_FILTER_OPTIONS);
  return response;
};

export const getPreventiveMaintenanceData = async (category) => {
  const response = await apiService.get(PREVENTIVE_MAINTENANCE_API, {
    queryParams: category,
  });
  return response;
};

export const getCorrectiveMaintenanceData = async () => {
  const response = await apiService.get(CORRECTIVE_MAINTENANCE_API);
  return response;
};

export const getRemedialMaintenanceData = async () => {
  const response = await apiService.get(REMEDIAL_MAINTENANCE_API);
  return response;
};
