import React, { useState, useContext } from "react";
import Head from "../../../layout/head/Head";
import { Card, Col, FormGroup, Modal, ModalBody, Row } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
} from "../../../components/Component";
import { UserManagementContext } from "../UserManagementProvider";
import { AuthContext } from "../../../providers/AuthContext.provider";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import { formatDistance } from "date-fns";

const UserProfileSettingPage = ({ sm, updateSm }) => {
  const userManagementContext = useContext(UserManagementContext);

  const authContext = useContext(AuthContext);
  const { currentUser } = authContext;

  const [modalTab, setModalTab] = useState("1");
  const [newPassword, setNewPassword] = useState({});
  const [confirmPassword, setConfirmPassword] = useState({});
  const [passwordState, setPasswordState] = useState(false);

  const [modal, setModal] = useState(false);

  const handlePasswordSubmit = () => {
    const password = {
      password: newPassword.new_password,
    };
    userManagementContext
      .userClientPassword(currentUser.id, password)
      .then((res) => {
        setModal(false);
        setNewPassword({});
        setConfirmPassword({});
        successToast({ description: "Password Updated Successfully " });
      })
      .catch((e) => {
        errorToast({ description: `${e}` });
      });
  };

  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>

      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Security Settings</BlockTitle>
            <BlockDes>
              <p>These settings will help you to keep your account secure.</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <Card className="card-bordered m-3">
          <div className="card-inner-group">
            <div className="card-inner">
              <div className="between-center flex-wrap g-3">
                <div className="nk-block-text">
                  <h6>Change Password</h6>
                  <p>Set a unique password to protect your account.</p>
                </div>
                <div className="nk-block-actions flex-shrink-sm-0">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                    <li className="order-md-last">
                      <Button color="primary" onClick={() => setModal(true)}>
                        Change Password
                      </Button>
                    </li>
                    <li>
                      <em className="text-soft text-date fs-12px">
                        Last Active:
                        <span className="profile-ud-value">
                          {currentUser.lastActiveAt
                            ? formatDistance(new Date(currentUser?.lastActiveAt || null), new Date(), {
                                addSuffix: true,
                              })
                            : "-"}
                        </span>
                      </em>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Block>
      <Modal isOpen={modal} className="modal-dialog-centered" size="lg" toggle={() => setModal(false)}>
        <ModalBody>
          <a
            href="#dropdownitem"
            onClick={(ev) => {
              ev.preventDefault();
              setModal(false);
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title mb-4">Change Password</h5>

            <div className="tab-content">
              <div className={`tab-pane ${modalTab === "1" ? "active" : ""}`} id="personal">
                <Row className="gy-4">
                  <Col md="6">
                    <FormGroup>
                      <div className="form-label-group">
                        <label className="form-label" htmlFor="new-password">
                          New Password
                        </label>
                      </div>
                      <div className="form-control-wrap">
                        <a
                          href="#new-password"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setPasswordState(!passwordState);
                          }}
                          className={`form-icon lg form-icon-right passcode-switch ${
                            passwordState ? "is-hidden" : "is-shown"
                          }`}
                        >
                          <Icon name="eye" className="passcode-icon icon-show"></Icon>

                          <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                        </a>
                        <input
                          type={passwordState ? "text" : "password"}
                          id="new-password"
                          value={newPassword.new_password || ""}
                          required
                          autoComplete="new-password"
                          onChange={(e) =>
                            setNewPassword({
                              ...newPassword,
                              new_password: e.target.value,
                            })
                          }
                          placeholder="New password"
                          name="password"
                          className="form-control"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <div className="form-label-group">
                        <label className="form-label" htmlFor="confirm-password">
                          Confirm Password
                        </label>
                      </div>
                      <div className="form-control-wrap">
                        <input
                          type="password"
                          id="confirm-password"
                          value={confirmPassword.confirm_password || ""}
                          required
                          onChange={(e) =>
                            setConfirmPassword({
                              ...confirmPassword,
                              confirm_password: e.target.value,
                            })
                          }
                          placeholder="confirm password"
                          className="form-control"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button
                          color="primary"
                          size="lg"
                          onClick={() => {
                            if (newPassword.new_password === confirmPassword.confirm_password) {
                              handlePasswordSubmit();
                            } else if (newPassword.new_password !== confirmPassword.confirm_password) {
                              errorToast({ description: "Both password should be same" });
                            }
                          }}
                        >
                          Reset Password
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#dropdownitem"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setModal(false);
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default UserProfileSettingPage;
