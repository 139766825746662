import { format } from "date-fns";
import React, { useContext } from "react";
import { Col, FormGroup, Spinner } from "reactstrap";
import { BlockHead, BlockHeadContent, BlockTitle } from "../../../components/Component";
import Attachments from "../../../components/attachments/Attachment";
import { TASK } from "../../equipment_spares/constants";
import { PvPlantContext } from "../PvPlantProvider";

const ViewCorrectiveTask = ({ sm, updateSm }) => {
  const pvPlantContext = useContext(PvPlantContext);
  const { currentViewTask } = pvPlantContext;
  if (!currentViewTask) return <Spinner />;
  return (
    <div className="overflow-auto h-max-450px">
      <div className={"bg-white overflow-auto"}>
        <BlockHead size="md">
          <div id="top" className="d-md-flex justify-content-md-between">
            <BlockHeadContent>
              <BlockTitle tag="h4"> {currentViewTask.title}</BlockTitle>
              <BlockTitle tag="h6">{currentViewTask.identifier} </BlockTitle>
            </BlockHeadContent>
          </div>
        </BlockHead>

        <div className="nk-data data-list">
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Ticket Name</span>
              <span className="data-value">{currentViewTask.title || "-"} </span>
            </div>
            <div className="data-col data-col-end"></div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Plant Name</span>
              <span className="data-value">{currentViewTask?.plant.name || "-"} </span>
            </div>
            <div className="data-col data-col-end"></div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Start Date</span>
              <span className="data-value">
                {currentViewTask?.startedAt ? format(new Date(currentViewTask?.startedAt), "dd MMM yyyy") : "-"}
              </span>
            </div>
            <div className="data-col data-col-end"></div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Resolved Date</span>
              <span className="data-value">
                {" "}
                {currentViewTask?.resolvedAt
                  ? format(new Date(currentViewTask?.resolvedAt || null), "dd/MM/yyyy")
                  : "-"}
              </span>
            </div>
            <div className="data-col data-col-end"></div>
          </div>

          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Assigned Engineer</span>
              <span className="data-value">
                {currentViewTask?.assignedEngineer
                  ? currentViewTask?.assignedEngineer
                      ?.map((item, key) => {
                        return `${item.firstName} ${item.lastName}`;
                      })
                      .join(", ")
                  : "-"}
              </span>
            </div>
            <div className="data-col data-col-end"></div>
          </div>

          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Description</span>
              <span className="data-value"> {currentViewTask.description || "-"}</span>
            </div>
            <div className="data-col data-col-end"></div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Comments</span>
              <span className="data-value"> {currentViewTask?.comment || "-"}</span>
            </div>
            <div className="data-col data-col-end"></div>
          </div>
          {/* <div className="data-item">
            <div className="data-col">
              <span className="data-label">Labour Hours</span>
              <span className="data-value"> {currentViewTask.labourHours || "-"}</span>
            </div>
            <div className="data-col data-col-end"></div>
          </div> */}
          <Col md="12" className="p-1">
            <FormGroup className="form-group">
              <div className="form-control-wrap">
                <Attachments
                  module={TASK}
                  id={currentViewTask.id}
                  attachments={currentViewTask.attachment}
                  subModule={"view"}
                />
              </div>
            </FormGroup>
          </Col>
        </div>
        {/* Footer */}
        <div className="nk-data data-list">
          <div>
            <small class="text-muted">
              CreatedAt: {format(new Date(currentViewTask?.created_at || null), "dd MMM yyyy HH:mm")}{" "}
            </small>
          </div>
          <div>
            <small class="text-muted">
              UpdatedAt: {format(new Date(currentViewTask?.updated_at || null), "dd MMM yyyy HH:mm")}{" "}
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewCorrectiveTask;
