import React from "react";
import useBoolean from "../../hooks/useBoolean";
import {
  getAllTickets,
  getPvPlantTask,
  getTaskListFilterOptions,
} from "./TicketManagementRepository";
import { format } from "date-fns";

const initialPaginationState = {
  page: 1,
  size: 10,
};

const initialFilterParams = {
  selectedTaskStatus: [],
  selectedAssignedEngineer: [],
  selectedPlant: [],
  isArchiveSelected: false,
  searchText: "",
  startDate: "",
  resolvedDate: "",
  sortingOrder: "",
  sortingField: "",
  category: "",
};

const initialFilterOptions = {
  statuses: [],
};

export const TicketManagementContext = React.createContext({
  getMaintenanceList: async (category) => {},
  getCorrectiveMaintenanceList: async () => {},
  getRemedialMaintenanceList: async () => {},
  maintenanceData: [],
  isFetchingPreventiveData: false,
});

export const TicketManagementProvider = (props) => {
  const [ticketsList, setTicketsList] = React.useState([]);
  const [currentViewTicket, setCurrentViewTicket] = React.useState(null);
  const [dashboardFilterStatus, setDashboardFilterStatus] = useBoolean(false);

  const [pagination, setPagination] = React.useState(Object.assign({}, initialPaginationState));
  const [filterParams, setFilterParams] = React.useState(Object.assign({}, initialFilterParams));
  const [filterOptions, setFilterOptions] = React.useState(Object.assign({}, initialFilterOptions));
  const [filtersLoading, flState] = useBoolean(false);

  //Loaders
  const [ticketsLoading, tlState] = useBoolean(false);

  //filter
  const [showFilter, sfState] = useBoolean(false);

  const getTicketListFilterParams = () => {
    const queryParams = { ...pagination };
    const {
      selectedTaskStatus,
      isArchiveSelected,
      searchText,
      selectedAssignedEngineer,
      resolvedDate,
      startDate,
      sortingField,
      sortingOrder,
      selectedPlant,
      category,
    } = filterParams;
    if (selectedTaskStatus.length) queryParams["taskStatus"] = selectedTaskStatus;
    if (selectedPlant.length) queryParams["taskPlant"] = selectedPlant;
    if (selectedAssignedEngineer.length) queryParams["taskAssignedEngineer"] = selectedAssignedEngineer;
    if (searchText) queryParams["taskText"] = searchText;
    if (isArchiveSelected != null) queryParams["taskArchived"] = isArchiveSelected;
    if (startDate) queryParams["taskStartDate"] = format(new Date(startDate || null), "yyyy-MM-dd");
    if (resolvedDate) queryParams["taskResolvedDate"] = format(new Date(resolvedDate || null), "yyyy-MM-dd");
    if (sortingField) queryParams["sortingField"] = sortingField;
    if (sortingOrder) queryParams["sortingOrder"] = sortingOrder;
    if (category) queryParams["taskCategory"] = category;

    return queryParams;
  };

  const loadTicketList = async (category, status, dashboard) => {
    tlState.on();
    if (status) {
      handleApplyFilter({ ...filterParams, selectedTaskStatus: [status] });
    }
    try {
      const filterParams = getTicketListFilterParams();
      if (category) {
        filterParams.taskCategory = category;
      }
      if (status) {
        filterParams.taskStatus = status;
      }
      if (dashboard) {
        filterParams.dashboard = "yes";
      }
      const response = await getAllTickets(filterParams);
      setTicketsList(response.taskList);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      tlState.off();
    }
  };

  // VIEW TICKET
  const loadPvPlantTicket = async (taskId) => {
    try {
      const response = await getPvPlantTask(taskId);
      setCurrentViewTicket(response.task);
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  const loadFilterOptions = async () => {
    flState.on();
    try {
      const response = await getTaskListFilterOptions();
      setFilterOptions(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      flState.off();
    }
  };
  const handleApplyFilter = (selectedFilters) => {
    setFilterParams(selectedFilters);
  };
  const resetPvPlantListFilter = () => setFilterParams(Object.assign({}, initialFilterParams));

  const checkIsFilterApplied = () => {
    if (
      filterParams.selectedTaskStatus.length != 0 ||
      filterParams.selectedAssignedEngineer != 0 ||
      filterParams.selectedPlant != 0 ||
      filterParams.isArchiveSelected ||
      filterParams.startDate ||
      filterParams.resolvedDate
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isFilterApplied = checkIsFilterApplied();

  const mContext = {
    isFilterApplied,
    checkIsFilterApplied,
    loadTicketList,
    ticketsLoading,
    pagination,
    ticketsList,
    showFilter,
    sfState,
    handleApplyFilter,
    resetPvPlantListFilter,
    currentViewTicket,
    loadPvPlantTicket,
    filtersLoading,
    filterOptions,
    filterParams,
    dashboardFilterStatus,
    setDashboardFilterStatus,
    loadFilterOptions,
    updateShowListSize: (size) => setPagination({ ...pagination, size, page: 1 }),
    updateShowListPage: (page) => setPagination({ ...pagination, page }),
  };
  return <TicketManagementContext.Provider value={mContext}>{props.children}</TicketManagementContext.Provider>;
};
export const withTicketManagementProvider = (Container, containerProps) => (props) =>
  (
    <TicketManagementProvider>
      <Container {...containerProps} {...props} />
    </TicketManagementProvider>
  );
